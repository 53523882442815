/*
 * File: CarCard.tsx
 * Project: autobuy-back-office
 * File Created: Tuesday, 22nd August 2023 04:50:14
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 24th January 2024 10:55:14
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import translator from "MultiLanguage/TranslationWrapper";
import Styles from "./CarCard.module.css";
import { RxCrossCircled } from "react-icons/rx";
import { LuImport } from "react-icons/lu";
import { CarType } from "Type/Car.type";
import CarSinglePic from "Content/Car/CarSinglePic/CarSinglePic";
import { useState } from "react";

type CarCardProps = {
    car: CarType,
    handleDelete?: Function,
    handlePictureImport?: Function,
    handleNavigation: Function,
}

function CarCard(props: CarCardProps) {
    return (
        <>
            <CarSinglePic id={props.car.id} Status={props.car.Status} handleNavigation={() => props.handleNavigation(props.car.id)} />
            {/* <div className={`${Styles.pictureBox}`} onClick={() => props.handleNavigation(props.car.id)}>
                {
                    props.car.Status === "SOLD" || props.car.Status === "RESERVED" ?
                    <div className={`${Styles.carStatusHolder}`}>
                        <h2 className={props.car.Status === "SOLD" ? Styles.sold : Styles.reserved} >{translator.decode(`car.status.${props.car.Status}`).toUpperCase()}</h2>
                    </div>
                    : null
                }
                <img className={`${Styles.picture}`} src={props.car.CarPicture && props.car.CarPicture.length > 0 ? props.car.CarPicture.sort((val1, val2) => val1.Index - val2.Index)[0].Pict.URI : "/Default.jpg"} alt={translator.decode("dynamic.carImage")} />
            </div> */}
            <div className={`${Styles.carBox} d-flex flex-column`}>
                <h3 className={`${Styles.carTitle} text-uppercase`} onClick={() => props.handleNavigation(props.car.id)}> {props.car.Brand} {props.car.Model} {props.car.Version} </h3>
                <div className="d-flex">
                    <div className={`${Styles.carInfo} w-25`}>
                        <p className={`text-uppercase`}>{props.car.RegNb}</p>
                        <p>{new Date(props.car.CirculationDate).toLocaleDateString()}</p>
                        {/* <p>{props.car.Mileage} km</p> */}
                    </div>
                </div>
                <div className={`d-flex flex-row gap-3 justify-content-end ${Styles.offerNView}`}>
                    <button className="btn btn-outline-primary" onClick={() => props.handleNavigation(props.car.id, true)}>{translator.decode("dynamic.modify")}</button>
                    <p className="font-weight-bold">{translator.decode("dynamic.views")}<span className="font-weight-normal">{props.car.SeenCar?.length ?? 0}</span></p>
                </div>
                <div className={`${Styles.icons}`}>
                    {
                    props.handlePictureImport ?
                        <LuImport className={`${Styles.import}`} height={"2rem"} width={"2rem"} onClick={(e) => {e.stopPropagation(); props.handlePictureImport!(props.car.id)}}/>
                        : null
                    }
                    {
                    props.handleDelete ?
                        <RxCrossCircled className={`${Styles.close}`} height={"2rem"} width={"2rem"} onClick={(e) => {e.stopPropagation(); props.handleDelete!(props.car.id, props.car.RegNb)}}/>
                        : null
                    }
                </div>
            </div>
        </>
    )
}

export default CarCard;
